<template>
  <div class="process main-panel large">
    <el-tabs v-model="activeProcess" @tab-click="changeTab" type="card">
      <el-tab-pane v-for="(process, processIndex) in processList"
                   :key="process.productionProgress.progressId"
                   :name="process.productionProgress.progressId">
        <template #label>
          <span :style="{color: ['#aaa', '#333', '#66cc33', '#ffae00', '#fe7e55'][process.productionProgress.status]}">{{ process.productionProgress.cardNo || `未开卡${processIndex}` }}</span>
        </template>
        <el-form inline @submit.prevent>
          <el-row>
            <el-col :span="6">
              <el-form-item label="订单号"><span>{{ process.productionProgress.progressId }}</span></el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="订单日期"><span>{{ $day(process.productionProgress.date).format("YYYY-MM-DD") }}</span></el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="胚布">{{ process.productionProgress.fabricName }}</el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="品名">{{ process.productionProgress.productName }}</el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="状态">
                <span :style="{color: ['#aaa', '#333', '#66cc33', '#ffae00', '#fe7e55'][process.productionProgress.status]}">
                  {{ ['取消', '正常', '普急', '中急', '特急'][process.productionProgress.status] }}
                </span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="色号">
            <div style="white-space: nowrap;">
                  <span style="margin-right: 10px;" v-if="!process.isUpdateColorcardId">
                    {{ process.productionProgress.colornumber }}
                  </span>
              <div style="display: inline-block; width: 120px; margin-right: 10px;" v-else>
                <el-select :size="controlSize" filterable v-model="process.colorcardId" placeholder="请选择色号">
                  <el-option v-for="item in colors" :key="item.id" :label="item.colornumber" :value="item.id" />
                </el-select>
              </div>
              <el-button-group v-if="process.productionProgress.status">
                <el-button type="danger"
                           :size="controlSize"
                           :loading="process.updColorcardLoading"
                           @click="updateColorcardId(processIndex)">修改</el-button>
                <el-button type="info"
                           v-if="process.isUpdateColorcardId"
                           :size="controlSize"
                           @click="!process.updColorcardLoading && (process.isUpdateColorcardId = false)">取消</el-button>
              </el-button-group>
            </div>
          </el-form-item>
          <el-form-item label="卡号" style="margin-left: 20px;">
            <div>
              <span style="margin-right: 10px;" v-if="process.productionProgress.cardNo && !process.isUpdateCardNo">
                {{ process.productionProgress.cardNo }}
              </span>
              <div style="display: inline-block; width: 120px; margin-right: 10px;" v-else>
                <el-input v-if="process.productionProgress.status" v-model="process.inputCardNo" :size="controlSize" oninput="value = value.replace(/\s/g, '')" />
              </div>
              <el-button type="danger"
                         v-if="process.productionProgress.status && !process.productionProgress.cardNo"
                         :size="controlSize"
                         :loading="process.openCardLoading"
                         @click="openCard(processIndex)">开卡</el-button>
              <el-button-group v-if="process.productionProgress.status && process.productionProgress.cardNo">
                <el-button type="danger"
                           :size="controlSize"
                           :loading="process.openCardLoading"
                           @click="updateCardNo(processIndex)">修改</el-button>
                <el-button type="info"
                           v-if="process.isUpdateCardNo"
                           :size="controlSize"
                           @click="!process.openCardLoading && (process.isUpdateCardNo = false)">取消</el-button>
              </el-button-group>
            </div>
          </el-form-item>
        </el-form>
        <div v-loading="isPageLoading" class="process-bar" :class="{'no-click': process.productionProgress.status === 0}">
          <my-process v-if="process.productionProgress.cardNo" :list="process.progress" mode="change" @change="handleChange" />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import _ from "lodash";
import MyProcess from "@/components/MyProcess/MyProcess";
import {isVoid} from "@/libs/utils";

export default {
  components: {MyProcess},

  props: {
    layerInfo: Object
  },

  data() {
    return {
      controlSize: "mini",
      inProcess: false,
      activeProcess: null,
      processIndex: null,
      isPageLoading: false,

      processList: [],
      colors: []
    }
  },

  async mounted() {
    this.processList = _.cloneDeep(this.layerInfo)
    this.activeProcess = this.processList[0].productionProgress.progressId;
    this.processIndex = 0;
    const products = await this.$getList.getProducts();
    this.colors = products
        .find(i => i.id === this.processList[0].productionProgress.productId).colorcards
        .map(i => ({...i, name: i.colornumber}))
  },

  methods: {
    changeTab(val) {
      this.processIndex = +val.index
    },
    async openCard(index) {
      if (!this.processList[index].inputCardNo) {
        this.$message.warning("请填入卡号")
        return false
      }
      this.processList[index].openCardLoading = true
      const sendData = {
        productionProgress: {
          id: this.processList[index].productionProgress.id,
          cardNo: this.processList[index].inputCardNo
        }
      }
      const res = await this.save(sendData)
      if (res) {
        this.$message.success("开卡成功")
        this.processList[index].productionProgress.cardNo = this.processList[index].inputCardNo
        this.$emit("handle", {mode: "reload"})
      }
      this.processList[index].openCardLoading = false
    },

    async updateCardNo(index) {
      if (!this.processList[index].inputCardNo) {
        this.$message.warning("请填入卡号")
        return false
      }
      if (this.processList[index].isUpdateCardNo) {
        this.processList[index].openCardLoading = true
        const sendData = {
          productionProgress: {
            id: this.processList[index].productionProgress.id,
            cardNo: this.processList[index].inputCardNo
          }
        }
        const res = await this.save(sendData)
        if (res) {
          this.$message.success("修改成功")
          this.processList[index].productionProgress.cardNo = this.processList[index].inputCardNo
          this.processList[index].isUpdateCardNo = false
          this.$emit("handle", {mode: "reload"})
        }
        this.processList[index].openCardLoading = false
      } else {
        this.processList[index].inputCardNo = this.processList[index].productionProgress.cardNo
        this.processList[index].isUpdateCardNo = true
      }
    },

    async updateColorcardId(index) {
      if (this.processList[index].isUpdateColorcardId) {
        this.processList[index].updColorcardLoading = true
        const sendData = {
          productionProgress: {
            id: this.processList[index].productionProgress.id,
            colorcardId: this.processList[index].colorcardId
          }
        }
        const res = await this.save(sendData)
        if (res) {
          this.$message.success("修改成功")
          this.processList[index].productionProgress.colorcardId = this.processList[index].colorcardId
          this.processList[index].productionProgress.colornumber = this.colors.find(color => color.id === this.processList[index].colorcardId).colornumber
          this.processList[index].isUpdateColorcardId = false
          this.$emit("handle", {mode: "reload"})
        }
        this.processList[index].updColorcardLoading = false
      } else {
        this.processList[index].colorcardId = this.processList[index].productionProgress.colorcardId
        this.processList[index].isUpdateColorcardId = true
      }
    },

    async handleChange(data) {
      if (data.changeMode === "active") {
        this.changeActive(data)
        await this.handleChangeSave()
      }
      if (data.changeMode === "content") {
        this.processList[this.processIndex].progress[data.index].content = data.content;
        if (!data.isChecked && !isVoid(data.content)) {
          this.changeActive(data)
        }
        await this.handleChangeSave()
      }
      if (data.changeMode === "select") {
        this.processList[this.processIndex].progress[data.index].processchildId = data.processchildId;
        if (!data.isChecked && !isVoid(data.processchildId)) {
          this.changeActive(data)
        }
        await this.handleChangeSave()
      }
    },

    async handleChangeSave() {
      const sendData = {
        productionProgress: {
          progressId: this.processList[this.processIndex].productionProgress.progressId
        },
        progressParents: this.processList[this.processIndex].progress.map(process => {
          const obj = {
            id: process.id,
            activeflag: Number(process.isChecked)
          }
          if (Reflect.has(process, "content")) obj.content = process.content;
          if (Reflect.has(process, "processchildId")) obj.processchildId = process.processchildId;
          return obj;
        })
      }

      const res = await this.save(sendData)
      if (res) {
        this.$emit("handle", {mode: "reload"})
      }
    },

    changeActive(data) {
      // console.log(data)
      const flag = !data.isChecked
      if (flag) {
        for (let i = data.index; i >= 0; i--) {
          this.processList[this.processIndex].progress[i].isChecked = flag
        }
      } else {
        let progressLen = this.processList[this.processIndex].progress.length;
        for (let i = data.index; i <= progressLen - 1; i++) {
          this.processList[this.processIndex].progress[i].isChecked = flag
          if (i + 1 !== progressLen) {
            if (!this.processList[this.processIndex].progress[i + 1].isChecked) break;
          }
        }
      }
      // console.log(this.processList[this.processIndex])
    },

    async save(sendData) {
      this.isPageLoading = true
      const res = await this.$api.Production.ProductionProcess.update(sendData).catch(() => {})
      this.isPageLoading = false
      if (res?.code === 200) {
        return res
      } else {
        this.processList = _.cloneDeep(this.layerInfo)
        this.$message.error(res?.msg || "保存失败")
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "process";
</style>
