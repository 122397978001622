<template>
  <div class="my-process">
    <div class="process-list">
      <template v-for="(item, index) in list" :key="item.id || index">
        <i class="arrow el-icon-right" v-if="index !== 0"></i>
        <div class="process-item"
             :class="{active: mode === 'change' && item.isChecked}"
             @click="handleClick({...item, index, changeMode: 'active'})">
          <i class="has-content" @click.stop="handleClick({...item, index, changeMode: 'content'})"></i>
          <i class="has-child el-icon-caret-bottom"
             v-if="item.child.filter(i => i.delflag).length"
             @click.stop="handleClick({...item, index, changeMode: 'select'})"></i>
          <span>{{ item.name }}</span>
        </div>
      </template>
      <template v-if="mode === 'edit'">
        <i class="arrow el-icon-right" v-if="list.length"></i>
        <div class="process-item" @click="openLayer()"><i class="el-icon-plus"></i></div>
      </template>
    </div>

    <my-layer v-if="Object.keys(layerInfo).length"
              :title="layerInfo.layerTitle"
              :component="layerComponent"
              :layer-info="layerInfo"
              @handle="handleOption"
              padding="20px"
              @close="layerInfo = {}" />
  </div>
</template>

<script>
import {shallowRef} from "vue";
import MyLayer from "@/components/MyLayer/MyLayer";
import _ from "lodash"

export default {
  components: {
    MyLayer
  },

  props: {
    list: {
      type: Array,
      default: () => []
    },

    mode: {
      type: String,
      default: () => ""
    }
  },

  data() {
    return {
      layerComponent: undefined,
      layerInfo: {}
    }
  },

  methods: {
    handleClick(info) {
      this.mode === "edit" && this.openLayer(_.cloneDeep(info))
      if (this.mode === "change") {
        const nextIsLast = this.list.length - 1 === info.index
        console.log(nextIsLast, this.list.length - 1, info.index)
        if (!nextIsLast && this.list[info.index + 1].isChecked) return false;
        info.changeMode === "active" && this.$emit("change", _.cloneDeep(info));
        (info.changeMode === "content" || info.changeMode === "select") && this.openLayer(_.cloneDeep(info));
      }
    },

    async openLayer(info = {}) {
      if (this.mode === "change" && info.changeMode) {
        info.changeMode === "content" && (this.layerComponent = shallowRef((await import("./content-edit/content-edit")).default))
        info.changeMode === "select" && (this.layerComponent = shallowRef((await import("./child-select/child-select")).default))
        this.layerInfo = {..._.cloneDeep(info), layerTitle: info.name}
      } else {
        this.layerComponent = shallowRef((await import("./edit/edit")).default)
        if (Object.keys(info).length) {
          this.layerInfo = {..._.cloneDeep(info), layerTitle: `${info.name}修改`, mode: "edit"}
        } else {
          this.layerInfo = {layerTitle: "新增", mode: "add"}
        }
      }
    },

    handleOption(data) {
      // 流程编辑模式下
      if (this.mode === "edit") {
        data.mode === "add" && this.$emit("add", data)
        data.mode === "edit" && this.$emit("edit", data)
      }
      // 流程变化模式下
      this.mode === "change" && this.$emit("change", data)
      this.layerInfo = {}
    }
  }
}
</script>

<style lang="scss" scoped>
@import "MyProcess";
</style>
